<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isActive"
    backdrop
    bg-variant="white"
    title="Tipo"
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-header"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #footer>
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-input
              v-model="model.name"
              rules="required"
              label="Nome"
              placeholder="informe o nome do tipo"
              name="name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-vue-select
              v-model="model.type"
              label="Grupo"
              :options="typesIndexes"
              rules="required"
              placeholder="Tipos"
              name="type"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-sidebar>
</template>

<script>
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { alphaNum, email, required } from '@validations'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'TypeForm',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
  },
  props: {
    modelUuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      typesIndexes: [],
      model: {
        name: '',
        uuid: null,
        type: {
          value: null,
          text: 'Selecione',
        },
      },
    }
  },
  watch: {
    modelUuid(data) {
      if (data === '') {
        return
      }
      this.getData(data)
    },
  },
  async mounted() {
    const indexes = await this.indexes()
    this.typesIndexes = indexes.map(data => ({
      value: data.uuid,
      text: data.name,
    }))
  },
  methods: {
    ...mapActions('typeIndex', ['indexes']),
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          data.type = this.model.type.value
          if (this.model.uuid) {
            response = await this.$http.put(`types/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('types', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Tipo salvo com sucess.')
          this.$refs.refFormObserver.reset()
          this.model = {
            name: '',
            uuid: null,
            type: {
              value: null,
              text: 'Selecione',
            },
          }
          this.$emit('onModelSave', true)
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.model = { ...this.blankUser }
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`types/${uuid}`)
      if (!response.error) {
        this.model = response
        this.model.type = this.typesIndexes.find(item => response.type.uuid === item.value)
      }
    },
  },
}
</script>

<style scoped>

</style>
