<template>
  <div>
    <page-header
      name="Tipos"
    >
      <b-button
        variant="primary"
        @click="newType"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <b-card>
      <b-row class="p-1">
        <b-col
          sm="12"
          md="12"
          class="p-0"
        >
          <h2>Filtros</h2>
        </b-col>
        <b-col
          md="4"
          class="p-0 pr-1"
        >
          <b-form-input
            v-model="search.search"
            class="filter-input"
            placeholder="Pesquisar por Nome"
            name="nome"
          />
        </b-col>
        <b-col
          md="3"
          class="p-0"
        >
          <i2-form-vue-select
            v-model="search.type"
            label=""
            :options="typesIndexes"
            placeholder="Tipos"
            :multiple="true"
            name="tipe"
          />
        </b-col>
        <b-col
          sm="12"
          md="2"
        >
          <b-button
            variant="secondary"
            type="button"
            @click="getTypes"
          >
            <FeatherIcon icon="SearchIcon" />
            <span
              class="text-nowrap"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getTypes"
    >
      <template
        #cell(type)="data"
        style="width: 20%"
      >
        {{ data.item.type.name }}
      </template>
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-4 text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentType(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />
        </div>
      </template>
    </Grid>
    <type-form
      :is-active.sync="isActivated"
      :model-uuid="currentType"
      @onModelSave="onTypeSave()"
      @onCancel="onCancelEdit()"
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import TypeForm from '@/views/types/TypeForm.vue'
import PageHeader from '@/components/i2/layout/PageHeader'

export default {
  name: 'TypeList',
  components: { PageHeader, TypeForm },
  data() {
    return {
      search: {
        search: '',
        type: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-40',
        },

        {
          key: 'type',
          label: 'Grupo',
          class: 'w-40',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      items: [],
      currentType: '',
      typesIndexes: [],
      isActivated: false,
    }
  },
  async mounted() {
    const indexes = await this.indexes()
    this.typesIndexes = indexes.map(data => ({
      value: data.uuid,
      text: data.name,
    }))
    await this.getTypes(1)
  },
  methods: {
    ...mapActions('typeIndex', ['indexes']),
    setCurrentType(data) {
      this.currentType = data.uuid
      this.isActivated = true
    },
    deleteType() {

    },
    newType() {
      this.currentType = ''
      this.isActivated = true
    },
    async getTypes(page) {
      this.items = await this.$search.get(
        'types',
        page,
        this.search,
        '',
      )
    },
    onTypeSave() {
      this.currentType = ''
      this.isActivated = false
      this.getTypes()
    },
    onCancelEdit() {
      this.isActivated = false
      this.currentType = ''
    },
    onDelete(type) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão do tipo.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`types/${type.uuid}`)
            if (response.error) {
              this.$notify.error(response.error_message)
              return
            }
            this.$notify.success('Tipo removido com sucesso.')
            this.getTypes()
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
